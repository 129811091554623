import {Routes} from '@angular/router';

export const erpRoutes: Routes = [
  {
    path: 'erp/customers',
    loadChildren: () => import('./customer/customer.module').then(m => m.AppCustomerModule),
    data: {
      pageTitle: 'partner.customer.home.title'
    }
  },
  {
    path: 'erp/providers',
    loadChildren: () => import('./provider/provider.module').then(m => m.AppProviderModule),
    data: {
      pageTitle: 'partner.provider.home.title'
    }
  },
  {
    path: 'erp/products',
    loadChildren: () => import('./product/product.module').then(m => m.AppProductModule),
    data: {
      pageTitle: 'product.product.home.title'
    }
  },
  {
    path: 'erp/services',
    loadChildren: () => import('./service/service.module').then(m => m.AppServiceModule),
    data: {
      pageTitle: 'product.service.home.title'
    }
  },
  {
    path: 'erp/products-groups',
    loadChildren: () => import('./product-group/product-group.module').then(m => m.AppProductGroupModule),
    data: {
      pageTitle: 'productGroup.home.title'
    }
  },
  {
    path: 'erp/units-of-measures',
    loadChildren: () => import('./unit-of-measure/unit-of-measure.module').then(m => m.AppUnitOfMeasureModule),
    data: {
      pageTitle: 'unitOfMeasure.home.title'
    }
  }
];
